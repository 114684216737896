
import { NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

const Custom404: NextPage<any> = () => {
  const router = useRouter()
  const currentLang =  router.locale

  return (
    <section className="relative h-screen py-24 sm:py-64 bg-gray-50">
      <Image className="absolute top-0 left-0 h-full w-full object-cover" width="1676" height="875" src="https://api.greenvalue.gr/wp-content/uploads/2023/01/servicesbackground-scaled.jpg" alt="not found background"></Image>
      <div className="relative container px-4 mx-auto">
        <div className="max-w-lg">
          <span className="text-sm font-semibold uppercase drop-shadow-md shadow-black">
            <span className="text-white">Error </span>
            <span className="text-green-500">404</span>
          </span>
          <h2 className="font-heading text-3xl sm:text-4xl text-white mt-2 mb-6 drop-shadow-md shadow-black">Page not found</h2>
          <p className="max-w-xl mx-auto text-lg leading-8 text-white mb-10 drop-shadow-md shadow-black">Sorry! We are unable to find the page you are looking for</p>
          <div className="sm:flex items-center">
            <Link className="inline-block w-full sm:w-auto py-4 px-6 mb-4 sm:mb-0 sm:mr-4 text-center font-heading font-medium text-base text-white bg-green-500 hover:bg-green-600 border border-green-500 hover:border-green-600 rounded-sm transition duration-200" href="/" locale={currentLang}>
              Go back to home page
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Custom404;